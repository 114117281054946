import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ListChoix324} from "./ListChoix324";
import {ErrorInput} from "../TimedFormInteractif/styleInters";

interface TimedSelect324CtrlProps {
    className?:string;
    Choices:Choice[];
    Current:Choice|null;
    id:string;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    Error:string|null;
    label:string;
    colorBorder?:string;
    isDisabled?:boolean;
    isSmaller?:boolean;
}

const TimedSelect324Ctrl = ({className, Current, Choices, id, setValue, label, Error, isDisabled}: TimedSelect324CtrlProps) => {
    const [search, setSearch] = useState<string>(Current?.libelle||"")
    const [coord, setCoord] = useState({x:0, y:0})
    const [open, setOpen] = useState<boolean>(false);
    const refInput = useRef<HTMLInputElement>(null)
    const [isFocus, setIsFocus] = useState(false)
    useEffect(() => {
        setSearch(Current?.libelle||"")
    }, [Current]);
    const ChoicesFiltered = useMemo(() => {
        const regex = new RegExp("" + search + "", 'gi');
        return Choices.filter(c=>c.libelle.match(regex))
    }, [search, Choices]);
    const HandleBlur = ()=>{
        setIsFocus(false)
        if(!open) {
            ValideChoice();
        }
    }
    const ValideChoice = ()=>{
        const myChoice = Choices.find(c=>c.libelle === search)



        const myVal = myChoice ? myChoice : null
        if(!myVal) setSearch("");
        setValue(myVal, id)

    }
    const handleChange = ()=>{
        if(refInput.current) {
            const val = refInput.current.value
            setSearch(val)
        }
    }
    const HandleKeyDown = (e:any)=>{
        if(e.key === 'Enter' || e.keyCode === 9){
            e.preventDefault();
            if(ChoicesFiltered.length>0){
                setSearch(ChoicesFiltered[0].libelle)
                setOpen(false);
            }
            ValideChoice()
        }
    }
    const SetMyCoord = (e:any)=>{
        e.target.select()
        setIsFocus(true)
        if (refInput.current) {
            refInput.current.select();
            const elt = refInput.current.getBoundingClientRect();
            const top = elt.top + elt.height;
            const left = elt.left;
            const width = elt.width;
            setCoord({x: left+width+5, y: top})
            setOpen(true);
        }
    }
    const ClickChoice = (c:Choice)=>{

        setOpen(false);
        setValue(c, id);

    }
    return (
        <div className={`select_324 ${className} ${Error ? "on_error" : ""} ${isFocus ? "focused" : ""} ${isDisabled ? "disabled" : ""}`}>
            <label>{label}</label>
            <div className={`wrap_input ${isDisabled ? "disabled" : ""}`}>
                <input disabled={isDisabled} id={"input" + id} className={`inputFirst`} ref={refInput} type={"text"} value={search}
                       onChange={handleChange} onBlur={HandleBlur} onFocus={SetMyCoord} onKeyDown={HandleKeyDown} autoComplete={"off"}/>
            </div>
            <ListChoix324 open={open} Zindex={10} ChoiceFiltered={ChoicesFiltered} setChoice={ClickChoice} Choices={Choices} closeMe={()=>setOpen(false)} x={coord.x} y={coord.y} id={id}/>
            {Error &&
                <ErrorInput>{Error}</ErrorInput>
            }
        </div>
    )
}

export const TimedSelect324 = styled(TimedSelect324Ctrl)`
    position: relative;
    width: 100%;
    
    label{
        font-weight: bold;
        display: block;
        font-size: 14px;
        margin-bottom: 2px;
    }
    &.on_error{
        .wrap_input {
            border: solid ${props => props.theme.Warning} 1px;
        }
    }
    &.focused{
        .wrap_input {
            border: solid ${props => props.theme.PrimaryLight} 1px;
        }
    }
    input{
        border:none;
        width: 100%;
        height: ${props=>props.isSmaller ? "30px"  : "36px"};
        padding-left: 5px;
        border-radius: 4px;
        background: ${props=>props.theme.colorBackInput};
    }
    .wrap_input{
        position: relative;
        width: 100%;
        border-radius: 4px;
        border-left: ${props => props.colorBorder ? `solid 4px ${props.colorBorder}` : "none"};
        &.disabled{
            input:hover{
                cursor: not-allowed;
            }
        }
        &:hover:not(.disabled){
            input{
                filter: brightness(97%);
            }
        }
    }
`
