import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {useGetPlageTimeUserDD} from "../../../../../../features/plageTime/plageTime.hooks";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import TimedSkeleton from "../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Historiques des heures',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

interface ChartHistoHeureDayCtrlProps{
    className?:string;
    idUser:number;
    dateStart:string;
    dateEnd:string;
}

const ChartHistoHeureDayCtrl = ({className, idUser, dateStart, dateEnd}:ChartHistoHeureDayCtrlProps)=>{
    const theme = useTheme();

    const [data, setData] = useState<any|null>(null);

    const PlageTimesQuery = useGetPlageTimeUserDD(idUser, new Date(dateStart), new Date(dateEnd))

    useEffect(()=>{
        if(PlageTimesQuery.data){
            const times = PlageTimesQuery.data;
            let myCurrent = new Date(dateStart);
            let last = new Date(dateEnd);
            let labels:string[] = [];
            let TimesWork:number[] = [];
            let TimesNoWorks:number[] = [];
            let TimesRegules:number[] = [];
            while(myCurrent<=last){
                labels.push(("0"+myCurrent.getDate()).slice(-2))
                const Plages = times.filter(p=>(new Date(p.plageAt.slice(0,10)+"T12:00:00")).toLocaleDateString() === myCurrent.toLocaleDateString())
                TimesNoWorks.push(Plages.reduce((acc, item)=>(acc+item.timeNoWork), 0))
                TimesWork.push(Plages.reduce((acc, item)=>(acc+item.timeDid), 0))
                TimesRegules.push(Plages.reduce((acc, item)=>(acc+item.timeRegule), 0))
                myCurrent = new Date(myCurrent.getTime() + 86400000);
            }
            const datasets = [
                {
                    label:'Heures Travaillées',
                    data:TimesWork,
                    backgroundColor: theme.PrimaryDark,
                },
                {
                    label:'Heures Non Travaillées',
                    data:TimesNoWorks,
                    backgroundColor: theme.ComplementaryExtraLight,
                },
                {
                    label:'Heures Regules',
                    data:TimesRegules,
                    backgroundColor: theme.SecondaryExtraLight,
                },
            ]
            setData({labels, datasets})
        }
    }, [PlageTimesQuery.data, dateStart, dateEnd])

    return (
        <div className={`ChartHeureDay ${className}`}>
            {PlageTimesQuery.isLoading ? <div className={"wrap-wait"}><TimedSkeleton width={"100%"} nbOccurence={1} type={"perso"} heightPerso={"154px"}/></div>: data === null ? 'null':
                <div className={`canvas-container`}>
                    <MyChartBar options={options} data={data}/>
                </div>
            }
        </div>
    )
}


const MyChartBar = ({...props})=>{
    return <Bar options={props.options}  data={props.data} />;
}

const ChartHistoHeureDay = styled(ChartHistoHeureDayCtrl)`
    width: 100%;
  padding: 10px 0;
  .canvas-container {
    height: 154px;
    width: 96%;
    margin: auto;
  }
`

export default ChartHistoHeureDay;
